<template>
    <div>
        <Navbar page="Feedback da assinatura" />
        <div class="mx-4 my-4 md:mx-8 md:my-8">
            <h1 class="font-r font-medium text-3xl mb-8">Cookie Banner da Fácil LGPD</h1>
        </div>

        <div class="max-w-md mx-auto">
            <p class="bg-blue-500 p-3" v-if="paymentIntent.status === 'loading...'">
                Checando informações...
            </p>
            <p class="bg-green-500 p-3" v-if="!error && paymentIntent.status === 'succeeded'">
                Sua assinatura foi confirmada com sucesso
            </p>
            <p v-if="error" class="bg-red-500 p-3">
                Não foi possível completar a assinatura {{ paymentIntent.status }}
            </p>

           
            <a class="p-2 bg-terciary mt-20 block w-20 text-center text-white" href="/stripe">Ok</a>
        </div>
    </div>
</template>

<script>
    export default {
        data() {
            return {
                route: 'stripe',
                paymentIntent: {
                    status: "loading..."
                },
                error: null,

            }
        },
        methods: {
            async start() {
                const configReq = await this.$http.get(`/v1/${this.route}/config`);
                const stripe = window.Stripe(configReq.data.publishableKey);

                const {error, paymentIntent} = await stripe.retrievePaymentIntent(
                    this.$route.query.payment_intent_client_secret,
                );

                this.paymentIntent = paymentIntent
                this.error = error
            },
        },

        async beforeMount() {
            this.start();
        }
    }
</script>